import { DOCUMENT, Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isNullOrUndefined } from 'ngx-unificator/helpers';
import { WindowService } from 'ngx-unificator/services';
import { distinct, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from './environment.service';
import { GlobalEventsService } from './global-events.service';
import { PlatformService } from './platform.service';
import { WrSocketsService } from './wr-sockets.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private _meta = inject(Meta);
  private _title = inject(Title);
  private _platform = inject(PlatformService);
  private _location = inject(Location);
  private _env = inject(EnvironmentService);
  private _globalEvents = inject(GlobalEventsService);
  private _wrSocket = inject(WrSocketsService);
  private _window = inject(WindowService);
  private _document = inject<Document>(DOCUMENT);


  private _localesForHeader = [
    'en', 'fi', 'de', 'no', 'pl', 'en-ca', 'en-au',
    'en-nz', 'fr-ca', 'de-at', 'fr', 'hu', 'ro',
    'es-mx', 'es', 'es-cl', 'es-pe', 'pt-br', 'pt', 'en-eu',
  ];

  /**
   * A configuration object that maps locale codes to mirror domain names.
   */
  private _mirrorConfig = {
    default: {
      default: '21bit',
      'en-au': '5',
    },
    '5': {
      default: '21bit',
      'en-au': '5',
    }
  }

  private _previousHtmlClass: string;

  public metaTitle: string;

  public originalLocationUrl: string;

  /**
   * Set in head Title & description
   * @param tags
   */
  public setMetaTags(tags: any) {
    this.metaTitle = tags.MetaTitle + ' - 21Bit casino';
    this._title.setTitle(tags.MetaTitle || '21Bit');
    this._meta.updateTag({ name: 'description', content: tags.MetaDesc || '' });

    if ('NoIndex' in tags && tags.NoIndex) {
      this.addMetaRobotNoIndex();
    }
  }

  /**
   * Add new alternate/canonical links and remove old links
   */
  public updateGlobalHeadLink() {
    this._globalEvents.routerNavigationEnd$.pipe(
      distinct(),
      tap(() => this._wrSocket.sendEventPageOpen()),
    ).subscribe((e) => {
      this._removeOldLinks(['alternate', 'canonical', 'x-default']);
      this._addHeadLinkAlternate();
      this._addHeadLinkXDefault();
      this._addHeadLinkCanonical();
      this._addLangTagForHTML(e);
      this._setOriginalUrl();
    });
  }

  private _setOriginalUrl() {
    this.originalLocationUrl = this._window.nativeWindow.location.origin;
  }

  /**
   * Add link alternate <link rel="alternate" href="https://www.21bit.com/en" hreflang="en">
   */
  private _addHeadLinkAlternate() {
    this._localesForHeader.forEach(e => {
      const link: HTMLLinkElement = this._document.createElement('link');
      let url: any = this._location.path().split('/');
      url[1] = e;
      url = url.join('/');
      link.setAttribute('rel', 'alternate');
      if (e !== 'en') {
        link.setAttribute('href', this._resolveMirrorHost(environment.app_host, e) + url);
        link.setAttribute('hreflang', e);
      } else {
        link.setAttribute('href', this._resolveMirrorHost(environment.app_host, e) + `/${url.split('/').slice(2).join('/')}`);
        link.setAttribute('hreflang', e);
      }
      this._document.head.appendChild(link);
    });
  }

  /**
   * Resolves the mirror host URL based on the current hostname and language.
   *
   * @param lang - The language to resolve the mirror host for.
   * @returns The resolved mirror host URL.
   */
  private _resolveMirrorHost(host, lang) {
    const hostname = host.split('.')[1];
    const mirrorNumber = hostname?.match(/\d+/g)[1];
    let resolvedHost = environment.app_host.replace('21bit', hostname);

    if (hostname === '21bit' || !hostname) {
      resolvedHost = this._mirrorConfig['default']?.[lang] ? '21bit' + this._mirrorConfig['default']?.[lang] : this._mirrorConfig['default']['default'];
    } else if (this._mirrorConfig[mirrorNumber]) {
      resolvedHost = !isNullOrUndefined(this._mirrorConfig[mirrorNumber]?.[lang]) ? '21bit' + this._mirrorConfig[mirrorNumber]?.[lang] : this._mirrorConfig[mirrorNumber]['default']
    } else {
      return resolvedHost;
    }

    return environment.app_host.replace('21bit', resolvedHost);
  }

  private _addHeadLinkXDefault() {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'x-default');
    link.setAttribute('href', environment.app_host + `/${this._location.path().split('/').slice(2).join('/')}`);
    this._document.head.appendChild(link);
  }

  private _addHeadLinkCanonical() {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', environment.app_host + `${this._location.path()}`);
    this._document.head.appendChild(link);
  }

  /**
   * Remove old alternate\canonical links from DOM
   * @param typeOfLinks
   */
  private _removeOldLinks(typeOfLinks: any[]) {
    if (this._platform.isBrowser) {
      typeOfLinks.forEach(e => {
        const oldLinks: NodeListOf<HTMLLinkElement> = this._document.querySelectorAll(`link[rel="${e}"]`);
        if (oldLinks.length) {
          oldLinks.forEach(j => {
            j.remove();
          });
        }
      });
    }
  }

  /**
   * Add in head <meta name="robots" content="noindex" />
   */
  public addMetaRobotNoIndex() {
    this._meta.addTags([
      { name: 'robot', content: 'noindex' },
    ]);
  }

  private _addLangTagForHTML(e) {
    const defaultLocale = 'en';
    const matches = this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) ? this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) : this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)/);
    const locale = (matches && this._env.env.languageList.map(e => e?.short?.split('-')[0]).indexOf(matches[1]) !== -1) ? matches[1] : defaultLocale;
    this._document.documentElement.lang = locale;
    const htmlClass = e.url.split('/')[2];
    if (htmlClass || (!htmlClass && this._previousHtmlClass)) {
      this._document.documentElement.classList.remove(this._previousHtmlClass);
      this._previousHtmlClass = htmlClass;
      Boolean(htmlClass) ? this._document.documentElement.classList.add(htmlClass) : null;
    }
  }

}
